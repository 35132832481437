export const mountZendeskScript = async () => {
  try {
    //* zendesk integration script
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=b5f14a37-0817-4b2a-862c-029679280e15'
    script.id = 'ze-snippet'
    await document.head.appendChild(script)

    return true
  } catch (error) {
    return false
  }
}

export const setZendeskToken = (token: string) => {
  //* zendesk login authentication script
  if (document.getElementById('ze-snippet')) {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.innerText = `zE('messenger', 'loginUser', function (callback) {
        callback("${token}")
      });`
    document.body.appendChild(script)
  }
}

export const openZendeskChat = () => {
  if (document.getElementById('ze-snippet')) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerText = "zE('messenger', 'open');"
    script.async = true
    document.body.appendChild(script)
  }
}

export const hideZendeskChat = () => {
  if (document.getElementById('ze-snippet')) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerText = "zE('messenger', 'open');"
    script.async = true
    document.body.appendChild(script)
  }
}

export const mountCustomerIoScript = async () => {
  try {
    //* customer io integration script
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.innerText = `
    var _cio = _cio || [];
    (function() {
      var a, b, c;
      a = function(f) { return function() { _cio.push([f].concat(Array.prototype.slice.call(arguments, 0))) }};
      b = ["load", "identify", "sidentify", "track", "page", "on", "off"];
      for (c = 0; c < b.length; c++) { _cio[b[c]] = a(b[c]) };
      var t = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id = 'cio-tracker';
      t.setAttribute('data-site-id', '97966070288f27d90c42');
      t.setAttribute('data-use-array-params', 'true');
      t.setAttribute('data-use-in-app', 'true');
      t.src = 'https://assets.customer.io/assets/track-eu.js';
      s.parentNode.insertBefore(t, s);
    })();
  `
    await document.body.appendChild(script)

    return true
  } catch (error) {
    return false
  }
}

export const identifyCustomerScript = (userEmail: string, userCreatedAt: string) => {
  const createdAt = Math.floor(Date.parse(userCreatedAt) / 1000) // Conversion en secondes si nécessaire

  if (!userEmail || typeof userEmail !== 'string') {
    console.error('Invalid userEmail provided.')
    return
  }

  // Customer.io identify script
  const script = document.createElement('script')
  script.async = true
  script.type = 'text/javascript'
  script.innerText = `
    _cio.identify({
      id: "${userEmail}", 
      created_at: ${createdAt}
    });
  `

  document.body.appendChild(script)
}
