<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useMeStore } from '@/stores/me'
import { RequestStatus } from '@/utils/restStore'
import { ref, watch } from 'vue'
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { useRoute } from 'vue-router'

import OnBoarding from '@/components/home/organisms/OnBoarding.vue'

import ProfileSelect from './components/generic/atoms/ProfileSelect.vue'
import ProfilesModal from './components/generic/organisms/ProfilesModal.vue'
import AppStructure from './components/generic/templates/AppStructure.vue'
import { profileChangeIsAvailable } from './utils/profile'
import {
  hideZendeskChat,
  identifyCustomerScript,
  mountCustomerIoScript,
  mountZendeskScript,
  setZendeskToken
} from './utils/script_integrations'

const authStore = useAuthStore()
const meStore = useMeStore()
let showOnboardingModal = ref(false)

const closeOnbardingModal = () => {
  showOnboardingModal.value = false
  if (!localStorage.getItem('operatorId')) meStore.postOnboarded()
}

const route = useRoute()

const setToken = async () => {
  await meStore.fetchToken()
  if (meStore.meToken.status === RequestStatus.RequestLoaded && document.getElementById('ze-snippet')) {
    setZendeskToken(meStore.meToken.data.token)
  }
}
watch(
  () => meStore.showOnboarding,
  async (value) => {
    if (!localStorage.getItem('operatorId')) showOnboardingModal.value = value
  }
)
watch(
  () => meStore.isUnfit,
  async (value) => {
    if (value) {
      if (document.getElementById('ze-snippet')) {
        hideZendeskChat()
      }
    }
  }
)
watch(
  () => authStore.isAuthenticated,
  async (value) => {
    if (value) {
      await meStore.fetchProfiles()
      const zendeskScriptLoaded = await mountZendeskScript()
      if (zendeskScriptLoaded) setToken()
      const customerScriptLoaded = await mountCustomerIoScript()
      if (customerScriptLoaded) {
        const loadCustomerData = async () => {
          if (meStore.me.status !== RequestStatus.RequestLoaded) {
            await meStore.fetchMe().catch(() => {})
          }
          if (meStore.me.status === RequestStatus.RequestLoaded) {
            identifyCustomerScript(meStore.me.data.email, meStore.me.data.createdAt)
          }
        }
        loadCustomerData()
      }
    }
  }
)

onMounted(async () => {
  if (meStore.me.status === RequestStatus.NoRequest) await meStore.fetchMe()
})
</script>

<template>
  <ProfilesModal
    v-if="meStore.me.status === RequestStatus.RequestLoaded && !meStore.selectedClient && !meStore.selectedCompany" />

  <OnBoarding :close-modal="closeOnbardingModal" v-if="showOnboardingModal" />
  <AppStructure>
    <ProfileSelect class="lg:hidden" v-if="profileChangeIsAvailable(route.name as string)" />
    <RouterView />
  </AppStructure>
</template>
